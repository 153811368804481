import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getBackLink, getHeaderTitles, getNextQuizLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 1
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"FIVE"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={
          "Participation in sports is linked to which of the following?"
        }
        answers={[
          "Lower rates of depression",
          "Reduced risk of obesity",
          "Fewer chronic illnesses",
          "All of the above",
        ]}
        correctAnswerNumber={3}
        answerDetail={
          "Girls who play sports optimize their physical and mental health. They are also less likely to be at risk for unhealthy behaviors and disease throughout their lifespan."
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 5, 6)
